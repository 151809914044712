<template>
    <footer class="footer mt-auto py-3">
        <b-navbar type="dark" variant="dark">
            <b-navbar-nav>
                <b-nav-text class="text-white">{{FOOTER}}<br>{{LICENSE}}</b-nav-text>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto">
                <b-nav-text class="text-white">For help, please contact Alan Reece at <u><a class="" target="_blank" href="https://outlook.office.com/mail/0/deeplink/compose?to=areece@jcboe.net&subject=Awards%20Portal%20Help">areece@jcboe.net</a></u> or at Main Campus extension 1611</b-nav-text>
            </b-navbar-nav>
        </b-navbar>
    </footer>
</template>
<script>
export default {
  name: "globalFooter",
  data: function () {
    return {
      LICENSE: `Exclusively licensed to Jefferson County High School, Dandridge, TN.`,
      FOOTER: `Designed and developed by Russell Payne 2021, All Rights Reserved - Build: ${this.$parent.VUE_APP_VER ? this.$parent.VUE_APP_VER : "dev build"}`,
    };
  },
};
</script>